.captcha {
  background-color: #f9f9f9;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  color: #4c4a4b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  width: 300px; /* Set a fixed width */
  margin: 1em auto; /* Center the captcha and adjust top/bottom margin */
}

@media screen and (max-width: 500px) {
  .captcha {
    flex-direction: column;
    width: auto; /* Adjust width for smaller screens */
  }
  .text {
    margin: 0.5em !important;
    text-align: center;
  }
  .logo {
    align-self: center !important;
  }
  .spinner {
    margin: 1em 0.5em 0.5em 0.5em !important; /* Reduce top margin */
  }
}

.text {
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5em;
}
.spinner {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  margin: 0.5em 0.5em;
  align-items: center;
  justify-content: center;
}
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
input[type="checkbox"] + .checkmark {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-color: #fcfcfc;
  border: 1.5px solid #c3c3c3;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
input[type="checkbox"] + .checkmark span {
  content: "";
  position: relative;
  margin-top: -3px;
  transform: rotate(45deg);
  width: 0.5em;
  height: 0.8em;
  opacity: 0;
}
input[type="checkbox"] + .checkmark > span:after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #029f56;
}
input[type="checkbox"] + .checkmark > span:before {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  bottom: 0;
  right: 0;
  background-color: #029f56;
}
input[type="checkbox"]:checked + .checkmark {
  animation: 2s spin forwards;
}
input[type="checkbox"]:checked + .checkmark > span {
  animation: 1s fadein 1.9s forwards;
}
input[type="checkbox"]:checked + .checkmark > span:after {
  animation: 0.3s bottomslide 2s forwards;
}
input[type="checkbox"]:checked + .checkmark > span:before {
  animation: 0.5s rightslide 2.2s forwards;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bottomslide {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rightslide {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  align-self: flex-end;
  margin: 0.5em 0;
}
.logo img {
  height: 1em;
  width: 1em;
  display: none;
}
.logo p {
  color: #9d9ba7;
  margin: 0;
  font-size: 0.75em;
  font-weight: 700;
  margin: 0.2em 0 0.1em 0;
}
.logo small {
  color: #9d9ba7;
  margin: 0;
  font-size: 0.6em;
}
@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }
  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: rgb(199, 218, 245);
  }
  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: rgb(199, 218, 245);
    border-right-color: rgb(89, 152, 239);
  }
  70% {
    border-width: 4px;
    border-color: rgb(199, 218, 245);
    border-right-color: rgb(89, 152, 239);
  }
  90% {
    border-width: 4px;
  }
  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}
::selection {
  background-color: transparent;
  color: teal;
}
::-moz-selection {
  background-color: transparent;
  color: teal;
}
